<template>
    <div class="examdatasummery">
        <div class="lessonclassfiy">
          <p>全部</p>
          <p v-for="(item, id) in lessonstag" :key="id" @click="examdatatag">
            {{ item.cataName }}
          </p>
        </div>
        <!-- <div class="examinfom" v-for="(item, index) in examdatalist" :key="index" @click="todetail(item.id)">
          <div class="item">
            <p class="itemtitle">{{ item.title }}</p>
            <p class="itemtime">{{ item.signSTime }}~{{ item.signETime }}</p>
            <p class="itemposition">{{ item.cataName }}·{{ item.province }}</p>
          </div>
        </div> -->

        <div class="examdatam">
            <div class="item" v-for="(item, index) in examdatalist" :key="index" @click="todetail(item.id)">
              <!-- <img :src="item.iconImg" alt="haibao" /> -->
              <van-image class="img"  lazy-load :src="item.iconImg" alt="haibao"></van-image>
              <div>
                <p class="itemtitle">{{ item.title }}</p>
                <p class="itemtime">{{ item.cataName }}</p>
                <p class="itemposition">{{ item.createdTime }}</p>
              </div>
            </div>
          </div>
      </div>
</template>

<script>
import axios from "axios";
import { Image as VanImage  } from 'vant';

export default {
    components:{
    [VanImage.name]:VanImage,
  }, 
  data() {
    return {
      lessonstag: [],
      examdatalist: [],
    };
  },
  methods: {
    getlessontag() {
      const url = "https://das.boxuanedu.cn/dd/rest/articleCata/export";
      axios
        .post(url)
        .then((res) => {
          this.lessonstag = res.data.data.list;
        })
        .catch((err) => console.log(err));
    },
    examdatatag(){
      const url="https://das.boxuanedu.cn/dd/rest/articleInfo/export";
      axios.post(url,{
        cataId:17
      }).then((res)=>console.log(res)).catch((err)=>console.log(err))
    },
    getexaminfolist() {
      this.examdatalist = JSON.parse(sessionStorage.getItem("examdatalist"));
      this.totalpage=this.examdatalist.length;
    },
    todetail(id){
        this.$router.push({name:'examdatadetail',query:{id}})
    },
  },
  created() {
    this.getlessontag();
    this.getexaminfolist();
    this.examdatatag()
  },
};
</script>

<style lang="less" scoped>
/deep/.van-image__img, .van-image__error, .van-image__loading{
    border-radius: 16px !important;
    
    object-fit: cover;
}
.examdatasummery {
    .lessonclassfiy {
      width: 100%;
      overflow: auto;
      display: flex;
      padding: 10px;
      background: #fff;
      p {
        flex: none;
        font-size: 16px;
        color: #8a9095;
        margin-right: 12px;
        padding: 0 0.13333rem;
      }
    }
    .examdatam {
        padding: 12px 16px 12px 16px;
        background: #dcebfd;
        .item {
          height: 108px;
          background: #f5f5f5;
          border: 1px solid #f5f5f5;
          border-radius: 12px;
          padding: 10px;
          margin-bottom: 12px;
          display: flex;
          .img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
            
          }
          .itemtitle {
            width: 210px;
            color: #020b10;
            font-weight: 500;
            font-size: 16px;
            font-family: "Roboto", sans-serif;
            margin-bottom: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .itemtime {
            margin-bottom: 30px;
            font-weight: 400;
            font-size: 12px;
            color: #0935d6;
          }
          .itemposition {
            font-size: 10px;
            color: #b1b5b9;
          }
        }
      }
  }
</style>